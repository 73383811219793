import {
	Navbar,
	NavbarBrand,
	Container,
	Nav,
	NavLink,
	NavDropdown,
	Button,
} from 'react-bootstrap';
import brandLogo from '../assets/images/sun_logo_transparent.png';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const NavigationBar = () => {
	const navigate = useNavigate();
	const ourFirm = useRef(null);
	const practiceAreas = useRef(null);

	//Effect to activate dropdowns for hover use
	useEffect(() => {
		let promise = new Promise((resolve) => {
			ourFirm.current.children[0].click();
			practiceAreas.current.children[0].click();
			resolve();
		});
		promise.then(() => {
			closeMenus();
		});
	}, []);

	const closeMenus = () => {
		let dropdowns = document.querySelectorAll('.dropdown-menu');
		dropdowns.forEach((element) => {
			element.classList.remove('show');
		});
	};

	return (
		<Navbar id="navigation-bar" fixed="top" expand="lg" collapseOnSelect>
			<Container fluid>
				<NavbarBrand>
					<Link to="/">
						<img id="navbar-logo" src={brandLogo} />
					</Link>
				</NavbarBrand>
				<NavbarToggle aria-controls="main-nav" />
				<NavbarCollapse id="main-nav">
					<Nav className="ms-auto">
						<NavLink onClick={() => navigate('/')} eventKey="1">
							Home
						</NavLink>
						<NavDropdown
							ref={ourFirm}
							className="menu-link"
							title="Our Firm"
							id="our-firm"
							onClick={() => ourFirm.current.blur()}
						>
							<NavDropdown.Item onClick={() => navigate('/about')} eventKey="2">
								<span className="menu-link">About Us</span>
							</NavDropdown.Item>
							<NavDropdown.Item onClick={() => navigate('/team')} eventKey="3">
								<span className="menu-link">Our Team</span>
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown
							ref={practiceAreas}
							className="menu-link"
							title="Practice Areas"
							id="practice-areas"
						>
							<NavDropdown.Item onClick={() => navigate('/wills')} eventKey="4">
								<span className="menu-link">Wills</span>
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => navigate('/trusts')}
								eventKey="5"
							>
								<span className="menu-link">Trusts</span>
							</NavDropdown.Item>
							<NavDropdown.Item
								onClick={() => navigate('/probate')}
								eventKey="6"
							>
								<span className="menu-link">Probates</span>
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
				</NavbarCollapse>
			</Container>
		</Navbar>
	);
};
export default NavigationBar;
