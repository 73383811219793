import { Container, Card } from 'react-bootstrap';

const Probate = () => {
	return (
		<div className="page">
			<Container fluid>
				<Card className="practice-area-card mt-4">
					<Card.Body>
						<Card.Title>
							<h1 className="text-center soft-highlight">Probate</h1>
							<hr />
						</Card.Title>
						<Card.Subtitle>
							<p className="text-center">
								Did someone you love die? Are you having difficulty accessing
								their assets? A Probate may be necessary.
							</p>
						</Card.Subtitle>
						<ul className="mt-4">
							<li>
								I was named "Personal Representative" or "Executor" in a will -{' '}
								<span className="highlight-text">
									Do you want legal advice from a Probate Attorney? Call us!
								</span>
							</li>
							<li>
								The bank won't let me access funds of my recently deceased loved
								one -{' '}
								<span className="highlight-text">
									Of course they won't! You need proper authorization from a
									Circuit County Judge.
								</span>
							</li>
							<li>
								Property is titled in my recently deceased loved one's name -{' '}
								<span className="highlight-text">
									A probate administration may retitle the asset to the
									appropriate person.
								</span>
							</li>
							<li>
								Summary Administration -{' '}
								<span className="highlight-text">
									Probate of a small estate
								</span>
							</li>{' '}
							<li>
								Formal Administration -{' '}
								<span className="highlight-text">Formal Administration</span>
							</li>
							<li>
								Ancillary Administration –{' '}
								<span className="highlight-text">
									A Non-resident of Florida has died leaving assets in the State
								</span>
							</li>
						</ul>
					</Card.Body>
				</Card>
			</Container>
		</div>
	);
};
export default Probate;
