import {
	Card,
	CardImg,
	Carousel,
	Col,
	Container,
	Row,
	Button,
} from 'react-bootstrap';
import NavigationBar from '../components/NavigationBar';
import SunshineStateLawOfficeSliderPicture1 from '../assets/images/SunshineStateLawOfficeSliderPicture1.jpg';
import SunshineStateLawOfficeSliderPicture2 from '../assets/images/SunshineStateLawOfficeSliderPicture2.jpg';
import SunshineStateLawOfficeSliderPicture3 from '../assets/images/SunshineStateLawOfficeSliderPicture3.jpg';
import SunshineStateLawOfficeSliderPicture4 from '../assets/images/SunshineStateLawOfficeSliderPicture4.jpg';
import SunshineStateLawOfficeSliderPicture5 from '../assets/images/SunshineStateLawOfficeSliderPicture5.jpg';
import estate_legacy from '../assets/images/estate_legacy.jpg';
import business_planning from '../assets/images/business_planning.jpg';
import real_estate from '../assets/images/real_estate.jpg';
import { useNavigate } from 'react-router-dom';

const Home = () => {
	const navigate = useNavigate();

	return (
		<div id="home-page" className="page">
			<div id="home-bg-div"></div>
			<Container fluid>
				<Row className="pt-4 pb-4 ms-auto me-auto practice-area-card">
					<Col md={4} className="mb-3 mb-md-0">
						<Card className="home-card">
							<Card.Body className="d-flex flex-column">
								<Card.Title>
									<h4>Wills</h4>
								</Card.Title>
								<CardImg src={estate_legacy} />
								<p>
									A Fort Myers wills attorney dedicated to you and your loved
									ones by providing a solid legal experience to plan for your
									future. We also serve clients with Powers of Attorney, Health
									Care Surrogates, and Living Wills.
								</p>
								<p>
									Ask about our Sunshine State Legacy Program – A customized
									Genealogy Report for all Wills & Trusts Clients
								</p>
								<Button
									className="form-control mt-auto"
									variant="outline-secondary"
									onClick={() => navigate('/wills')}
								>
									Read More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col md={4} className="mb-3 mb-md-0">
						<Card className="home-card">
							<Card.Body className="d-flex flex-column">
								<Card.Title>
									<h4>Trusts</h4>
								</Card.Title>
								<CardImg src={business_planning} />
								<p>
									A Fort Myers trust attorney committed to protecting your
									assets. We design Trusts to avoid probate, keep personal and
									financial matters private, and ease the financial and court
									process of administrations.
								</p>
								<p>
									You love your family. Now give yourself peace of mind and
									clarity to avoid court and legal costs with a Florida Living
									Revocable Trust.
								</p>
								<Button
									className="form-control mt-auto"
									variant="outline-secondary"
									onClick={() => navigate('/trusts')}
								>
									Read More
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col md={4}>
						<Card className="home-card">
							<Card.Body className="d-flex flex-column">
								<Card.Title>
									<h4>Probate</h4>
								</Card.Title>
								<CardImg src={real_estate} />
								<p>
									Did a loved one die? Let us help you! We are Fort Myers
									probate attorneys dedicated to you and your loved ones to
									efficiently administer estates. Let us guide you with proper
									legal advice. Would you like a free consultation?
								</p>
								<p>
									"Property is surely a right of mankind as real as liberty" –
									John Adams
								</p>
								<Button
									className="form-control mt-auto"
									variant="outline-secondary"
									onClick={() => navigate('/probate')}
								>
									Read More
								</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default Home;
