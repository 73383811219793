import { Container, Card, CardImg, Row, Col } from 'react-bootstrap';
import luke from '../assets/images/Luke-Johnson.jpg';
import monica from '../assets/images/Johnson _Monica_B_square.jpg';

const Team = () => {
	return (
		<div className="page">
			<Container fluid>
				<Card className="practice-area-card mt-4 mb-4">
					<Card.Body>
						<Card.Title>
							<h1 className="text-center soft-highlight">
								Fort Myers Attorneys
							</h1>
							<hr />
						</Card.Title>
						<Card className="team-card mt-4">
							<div className="d-flex flex-row flex-sm-row flex-wrap">
								{/* <Col xs={4}> */}
								<CardImg
									src={luke}
									className="m-3"
									style={{ maxWidth: '300px' }}
								/>
								{/* </Col> */}
								{/* <Col className="d-flex align-items-center"> */}
								<div className="d-flex align-items-center ms-3">
									<h1>Luke Johnson</h1>
								</div>
								{/* </Col> */}
							</div>
							<Card.Body>
								<p>
									Luke Johnson is founder and owner of Sunshine State Law
									Office. He focuses in Estate Planning, Wills, Trusts, and
									Probate. Luke is a family man raising two sons (ages 7 and 9)
									with his wife Monica in Fort Myers.
								</p>
								<p>
									Luke is a fourth generation native of Fort Myers. He attended
									college at the Lubin School of Business in New York, New York
									where he graduated with a Bachelor of Business Administration
									(B.B.A.). He received his Juris Doctor from Nova Southeastern
									University School of Law and returned to Fort Myers to set up
									his own practice. He enjoys spending time with his wife and
									two sons, Tabor and Theodore.
								</p>
							</Card.Body>
						</Card>
						<Card className="team-card mt-4 mb-4">
							<div className="d-flex flex-row flex-sm-row flex-wrap">
								{/* <Col xs={4}> */}
								<CardImg
									src={monica}
									className="m-3"
									style={{ maxWidth: '300px' }}
								/>
								{/* </Col> */}
								{/* <Col className="d-flex align-items-center"> */}
								<div className="d-flex align-items-center ms-3">
									<h1>Monica Johnson</h1>
								</div>
								{/* </Col> */}
							</div>
							<Card.Body>
								<p>
									Monica Johnson is a partner at Sunshine State Law Office. She
									focuses on Estate Planning, Wills, Trusts and Probate. Monica
									and her husband Luke, enjoy working together at the firm. They
									have two sons, who keep them busy!
								</p>
								<p>
									Monica grew up in St. Johnsbury, Vermont but is a successful
									transplant of Fort Myers, having now lived here for over 20
									years. She attended Syracuse University, where she graduated
									with a Bachelor of Arts in Psychology and Women's Studies. She
									received her Juris Doctor from Ave Maria School of Law, in
									Naples. While there she received the St. Francis Xavier
									Cabrini Award, for excellence shown in Wills and Trusts
									Clinic.
								</p>
								<p>
									In her spare time, Monica enjoys reading and movie nights with
									her family.
								</p>
							</Card.Body>
						</Card>
					</Card.Body>
				</Card>
			</Container>
		</div>
	);
};
export default Team;
