import { Container } from 'react-bootstrap';

const Footer = () => {
	return (
		<div id="main-footer">
			<Container className="text-center">
				<h5>Sunshine State Law, PLLC</h5>
				<div>2125 Victoria Avenue | Fort Myers, Florida 33901</div>
				<div>Telephone: 239-790-4477 | Fax: 239-201-2662</div>
			</Container>
		</div>
	);
};
export default Footer;
